@import "@fontsource/montserrat";

body, p, a, h1, h2, h3, h4, h5, h6, textarea, div, thead, th, td, tr, span, button, li, label, input {
  font-family: 'Montserrat' !important;
  color: '#1A1B1F';
}
body {
  background: "#F5F5F5"
}
input:disabled, div.ck.ck-content[contenteditable="false"] {
  background: #e9ecef !important;
  border-color: #acafb1 !important;
}
.MuiInputBase-root.Mui-disabled:hover fieldset {
  border-color: #acafb1 !important;
}
.next-steps .fa-link {
  margin-right: 5px;
}
.d-none {
  display: none;
}
/*  Site-wide loader */
.blur>*  {
  filter: blur(4px);
}
.siteLoader {
  color: #74c69d;
  position: absolute;
  left: calc(100vw - 50vw - 7%);
  top: calc(100vh - 50vh - 12%);
  z-index: 99999999;
  filter: blur(0px);
  height: 125px;
  width: 125px;
}
/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
div[data-editable="false"] {
  filter: opacity(0.5);
  pointer-events: none;
}


/*New Code starts here*/
/*
Color palette:
green: #74C69D
blue: #0A369D
black: #000
blueish gray: #597081 OR #36494E
*/
.accountLink:last-child .accountLinkDivider {
  display: none;
}
.greenCheck {
  color: #4ba45d;
}
.redX {
  color: #c7433d;
}
.green {
  color: #74c69d !important;
}
.red {
  color: #ff3a2c !important;
}
.white {
  color: #FFF;
}
button { 
  text-transform: unset !important;
}

/* hover on all table rows */
.tableBoxShadow {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}
.disableTableRow {
  filter: grayscale(1);
  transform: scale(1);
  pointer-events: none;
}
table tr td {
  text-align: center;
  font-size: 12px;
}
table tr td::first-letter {
  text-transform: uppercase;
}
tr:hover {
  background-color: #ecf3fa;
}
thead tr th {
  /* color: #0A369D !important; */
  color: #FFF !important;
}
thead tr:hover {
  background-color: inherit;
}

.newConnectionsTable tbody tr:nth-child(odd) {
  background: #eff7fc;
}
.newConnectionsTable tbody tr td {
  max-width: 75px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.newResponsesTable tbody tr td {
  max-width: 75px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.phoneTaskTable tbody tr td {
  font-size: 12px;
}
.newResponsesTable tbody tr:nth-child(odd) {
  background: #e9ebe6;
}
/* .campaignsTable tbody tr:nth-child(odd) {
  background: #FFF;
} */
.phoneTaskTable tbody tr:nth-child(odd) {
  background: #e4d8dd;
}
.commStatsTable tbody tr:nth-child(odd) {
  background: rgb(116 198 157 / 10%);
}
.campaignsTable thead tr th {
  /* color: #0A369D !important; */
  color: #000 !important;
}
.campaignsTable tbody tr td {
  max-width: 60px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table tbody tr:nth-child(even) {
  background: #FFF;
}

.MuiTabs-indicator {
  background-color: orange;
}
/* ckeditor styles */
.ck.ck-content {
  background: #FFF !important;
  border-radius: 7px !important;
  margin-top: 20px !important;
  min-height: 132px;
  height: auto;
  border: 2px solid #74c69d !important;
  padding-top: 3px;
}
.ck.ck-content.ck-focused {
  border: 2px solid #74c69d !important;
}
.ck.ck-content p, .ck.ck-content ol, .ck.ck-content li, .ck.ck-content ul, .ck.ck-content span {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: 'Arial' !important;
}
.ck.ck-editor__editable_inline {
  border: 2px solid #74c69d !important;
}
.emailMessagingFontOverride input, .emailMessagingFontOverride select, .emailMessagingFontOverride label {
  font-family: Arial !important;
}
/* linkedin ckeditor styles */

.linkedInMessengerWrapper .ck.ck-content {
  background: #FFF !important;
  border-radius: 7px !important;
  margin-top: 20px !important;
  min-height: 132px;
  height: auto;
  border: 2px solid #6199a5;
}
.linkedInMessengerWrapper .ck.ck-content.ck-focused, .linkedInMessengerWrapper .ck.ck-editor__editable_inline {
  border: 2px solid #6199a5 !important;
}

/* switches */
.MuiSwitch-colorPrimary.Mui-checked {
  color:#FFF !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background: #49a160 !important;
}
.MuiSwitch-colorPrimary.Mui-checked:hover {
  background-color: rgba(116, 198, 157, 0.1) !important;
}

/* navbar */
.MuiAppBar-colorPrimary {
  background: #74C69D !important;
}

/*  accordion */
.accordionStyles {
  box-shadow: unset !important;
  border: unset !important;
  margin: auto !important;
}
.MuiAccordion-root:before {
  background-color: unset !important;
}

/* Reusable styles */
.anchor {
  color: #0066f9;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  border: unset;
  outline: unset;
  padding: 0 !important;
}
.anchor:focus {
  outline: unset;
}